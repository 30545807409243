import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';

const ScaledComponent = ({ id, serverProblems, usernameProblems, passwordProblems }) => {

    const controls = useAnimation();

    const [servProblems, setServProblems] = useState(null);
    const [userProblems, setUserProblems] = useState(null);
    const [passProblems, setPassProblems] = useState(null);


    const animateOnPropsChange = async () => {
        await controls.start({ scale: 1.1 });
        await controls.start({ scale: 1 });
    };

    const animateOnExit = async () => {
        await controls.start({ scale: 0 });
    };

    //Tigger Animation start
    React.useEffect(() => {
        animateOnPropsChange();
    }, []);

    /****************************************************** Input Handling ***************************************************/

    useEffect(() => {
        
        //console.log('serverProblems', serverProblems);
        
        if(serverProblems && serverProblems[['problems']]){
            setServProblems(serverProblems['problems']);
        } else {
            setServProblems(null);
        }
    }, [serverProblems]);

    useEffect(() => {
        
        //console.log('usernameProblems', usernameProblems);
        
        if(usernameProblems && usernameProblems[['problems']]){
            setUserProblems(usernameProblems['problems']);
        } else {
            setUserProblems(null);
        }
    }, [usernameProblems]);

    useEffect(() => {

        //console.log('passwordProblems', passwordProblems);
        
        if(passwordProblems && passwordProblems[['problems']]){
            setPassProblems(passwordProblems[['problems']]);
        } else {
            setPassProblems(null);
        }
        
    }, [passwordProblems]);

    /*
    useEffect(() => {
        if(passProblems && passProblems.length > 0){
            console.log('ingame', passProblems)
        }
    }, [passProblems]);
    */

    /****************************************************** Content ***************************************************/
    return (
        <Container
            initial={{ scale: 0 }} // Initial scale when the component is mounted
            animate={controls} // Use controls to dynamically set the animation
            exit={{ scale: 0 }} // Exit animation configuration
            transition={{ duration: 0.2 }} // Transition duration in seconds
        >   
            {servProblems && servProblems.length > 0 && (
                <ProblemList>
                    {servProblems.map((problem) => (
                        <Problem key={problem}>
                            {problem}
                        </Problem>
                    ))}
                </ProblemList>
            )}

            {userProblems && userProblems.length > 0 && (
                <ProblemList>
                    {userProblems.map((problem) => (
                        <Problem key={problem}>
                            {problem}
                        </Problem>
                    ))}
                </ProblemList>
            )}

            {passProblems && passProblems.length > 0 && (
                <ProblemList>
                    {passProblems.map((problem) => (
                        <Problem key={problem}>
                            {problem}
                        </Problem>
                    ))}
                </ProblemList>
            )}
        </Container>
    );
};

const Container = styled(motion.div)`
    height: auto;
    width: 100%;
    display: flex;

    flex-direction: column;

    justify-content: center;
    align-items: center; 

    margin-bottom: 20px;
    background-color: #820000;
    padding: 5px;
    
    color: white;
    text-align: left;
    border-radius: 20px;
    font-size: 1.1rem;

    overflow: hidden;
`;

const ProblemList = styled.ul`
    height: 100%;
    width: 80%;
    display: flex;    
    flex-direction: column;
    justify-content: center;

    list-style: none;
    background: transparent;
    padding: 0;
`;

const Problem = styled.li`
    height: 100%;
    width: 100%;
    
    padding: 5px;
`;

export default ScaledComponent;
