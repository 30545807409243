// MainContainer.js
import React from 'react';
import styled from 'styled-components';
import BackgroundImage from './background_dark.jpeg'

const MainContainer = ({ children }) => {
  return (
    <App>
        <AppHeader>
          {children}
        </AppHeader>
    </App>
  );
};

export default MainContainer;

const App = styled.div`
    text-align: center;
    background-color: transparent;
    overflow: hidden;
`;

//background-image: url(${BackgroundImage});
//background-size: cover;

//min-height: 100vh;
const AppHeader = styled.div`
  
  position: relative; 
  height: auto;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: hidden;
  background-color: transparent;

  `;

