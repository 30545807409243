import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const DynamicSwitch = ({ list, index, updateRating }) => {

  const [isChecked, setChecked] = useState(0);
  const [rating, setRating] = useState(-1);
  const [totalOptions, setTotalOptions] = useState(-1);

  const handleDynamicSwitch = (index) => {
    setChecked(index);
  };

  useEffect(() => {
    if (true) {
      setRating(index);
    }
  }, [index]);

  useEffect(() => {
    if (list && list.length > 0) {
      const lengthOfArray = list.length;
      setTotalOptions(lengthOfArray)
    }
  }, [list]);

  useEffect(() => {
    if (updateRating) {
      updateRating(isChecked);
    }
  }, [isChecked]);

  /*
  useEffect(() => {
    if (updateRating && isChecked !== -1) {
      updateRating(isChecked);
    }
  }, [isChecked, updateRating]);

  useEffect(() => {
    if (rating_ !== null && rating_ !== -1) {
      setRating(rating_);
    }
  }, [rating_]);

  useEffect(() => {
    if (rating !== isChecked) {
      setChecked(rating);
    }
  }, [rating, isChecked]);
  */
  return (
    <DynamicSwitchContainer>
      <DynamicSwitchLabel>
        {list && list.length > 0 &&
          list.map((option, index) => (
            <DynamicSwitchTextLabel
              whileTap={{ scale: 0.95 }}
              whileHover={{ backgroundColor: '#FFF', color: '#820000' }}

              key={index}
              onClick={() => handleDynamicSwitch(index)}
              $index={index}
              $isChecked={isChecked === index}
              $totalOptions={totalOptions}
            >
              {option}
            </DynamicSwitchTextLabel>
          ))}
        <DynamicSwitchSlider
          $isChecked={isChecked}
          $totalOptions={totalOptions}
        />
      </DynamicSwitchLabel>
    </DynamicSwitchContainer>
  );
};

export default DynamicSwitch;

const DynamicSwitchContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  min-height: 6vh;
  width: 95%;
  background: transparent;
`;

const DynamicSwitchTextLabel = styled(motion.div)`
  overflow: hidden;
  position: relative;
  width: ${({ $totalOptions }) => 100 / $totalOptions}%;
  
  background: transparent;
  opacity: ${({ $isChecked }) => ($isChecked ? '0.5' : '1')};
  
  transition-duration: 0.5s;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1rem;

  cursor: pointer;
  padding: 10px;

  border-right: 1px solid white;
  ${({ $index }) => $index === 0 && `border-left: 1px solid white;`}

  &:hover {
    opacity: ${({ $isChecked }) => ($isChecked ? '0.7' : '1')};
  }
`;

const DynamicSwitchLabel = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #820000;
`;


const DynamicSwitchSlider = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  bottom: 0;
  border-bottom: 1px solid white;
  transition: transform 0.1s;
  transform: translateX(${({ $isChecked }) => $isChecked * (100)}%);
  width: ${({ $totalOptions }) => 100 / $totalOptions}%;
`;

const DynamicSwitchLabelGradient = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    #00a407,
    #4dc005,
    #a7e102,
    #f8f301,
    #ec9a06,
    #da0e0e
  );
`;
