import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';

import ScaledComponent from '../../animations/ScaledComponent/ScaledComponent'
import LoadingComponent from '../../animations/Loading/LoadingComponent'
import DynamicSwitch from '../Toggles/DynamicSwitchTransparent';

import showPassIcon from './../../images/icons/visibility_FILL0_wght400_GRAD0_opsz24.svg'
import hidePassIcon from './../../images/icons/visibility_off_FILL0_wght400_GRAD0_opsz24.svg'

/*
retUsername -> username handling
retPassword -> password handling
baseURL -> URL for DB access
props -> set current page
*/
const Login = ({ main_title, baseURL_auth, baseURL_authcookie, baseURL_createacc, baseURL_recovereacc, onLogin }) => {

    const LOGIN_FORM = 0;
    const CREATEACC_FORM = 1;

    const build = true;

    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [loading, SetLoading] = useState(false);
    const [redEmailInput, setRedEmailInput] = useState(false)
    const [showPassword, setShowPassword] = useState(false);

    const [formIndex, setFormIndex] = useState(0);
    const [recoveryPage, setRecoveryPage] = useState(false);

    const [id, setId] = useState(0);

    const [userMessage, setUserMessage] = useState('');
    const [usernameProblems, setUsernameProblems] = useState(null);
    const [passwordProblems, setPasswordProblems] = useState(null);
    const [serverProblems, setServerProblems] = useState(null);

    useEffect(() => {
        setId(id + 1)
    }, [userMessage]);


    /****************************************************** Login Handling ***************************************************/
    //Automatically log in (if possible)
    useEffect(() => {
        handleCookieLogin();
    }, []);

    //Handle automatic login
    const handleCookieLogin = async () => {

        SetLoading(true)

        const serverResponse = {
            isValid: true,
            problems: []
        };

        try {
            const response = await fetch(baseURL_authcookie, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();

            if (data) {
                if (data['success']) {
                    if (data['token'] && data['user']) {
                        let info = {
                            user: data['user'],
                            token: data['token'],
                        }
                        onLogin(info)
                    }  else {
                        serverResponse.isValid = false;
                        serverResponse.problems.push('An error occured, code: EMPTY_TOKEN_RESPONSE');
                        setServerProblems(serverResponse)
                    }

                } else if (data['error']) {
                    serverResponse.isValid = false;
                    serverResponse.problems.push(data['error']);
                    setServerProblems(serverResponse)

                } else {
                    serverResponse.isValid = false;
                    serverResponse.problems.push('An error occured, code: EMPTY_RESPONSE');
                    setServerProblems(serverResponse)
                }
            }

            //Handle the response data
        } catch (error) {
            //serverResponse.isValid = false;
            //serverResponse.problems.push('An error occured, code: SERVER_CONNECTION_FAILED');
            //setServerProblems(serverResponse)
        }

        SetLoading(false)
    };

    //Manual Login
    const handleLogin = async () => {

        let inputCheck = noProblems(false, false, false);

        if (inputCheck && inputCheck[['isValid']]) {

            SetLoading(true);

            const serverResponse = {
                isValid: true,
                problems: []
            };

            try {
                const response = await fetch(baseURL_auth, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ username: email, password: password }),
                });

                const data = await response.json();

                if (data) {

                    if (data['success']) {
                        if (data['token'] && data['user']) {
                            let info = {
                                user: data['user'],
                                token: data['token'],
                            }
                            onLogin(info)
                        }  else {
                            serverResponse.isValid = false;
                            serverResponse.problems.push('An error occured, code: EMPTY_TOKEN_RESPONSE');
                            setServerProblems(serverResponse)
                        }

                    } else if (data['error']) {
                        serverResponse.isValid = false;
                        serverResponse.problems.push(data['error']);
                        setServerProblems(serverResponse)

                    } else {
                        serverResponse.isValid = false;
                        serverResponse.problems.push('An error occured, code: EMPTY_RESPONSE');
                        setServerProblems(serverResponse)
                    }
                }

                // Handle the response data
            } catch (error) {
                serverResponse.isValid = false;
                serverResponse.problems.push('An error occured, code: SERVER_CONNECTION_FAILED');
                setServerProblems(serverResponse)
            }

            SetLoading(false)

        } else {
            setServerProblems(inputCheck);
        }
    };

    /****************************************************** Acc Creation Handling ***************************************************/

    //need to handle email validation
    const handleCreateAccount = async () => {

        //check for any input errors
        let inputCheck = noProblems(false, false, false);
        //console.log('inputCheck', inputCheck)

        if (inputCheck && inputCheck[['isValid']]) {

            SetLoading(true);

            const serverResponse = {
                isValid: true,
                problems: []
            };

            try {
                const response = await fetch(baseURL_createacc, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ username: email, password: password }),
                });

                const data = await response.json();

                if (data) {

                    if (data['success']) {
                        if (data['token'] && data['user']) {
                            let info = {
                                user: data['user'],
                                token: data['token'],
                            }
                            onLogin(info)
                        } else {
                            serverResponse.isValid = false;
                            serverResponse.problems.push('An error occured, code: EMPTY_TOKEN_RESPONSE');
                            setServerProblems(serverResponse)
                        }
                    } else if (data['error']) {
                        serverResponse.isValid = false;
                        serverResponse.problems.push(data['error']);
                        setServerProblems(serverResponse)

                    } else {
                        serverResponse.isValid = false;
                        serverResponse.problems.push('An error occured, code: EMPTY_RESPONSE');
                        setServerProblems(serverResponse)
                    }
                }
                // Handle the response data
            } catch (error) {
                serverResponse.isValid = false;
                serverResponse.problems.push('An error occured, code: SERVER_CONNECTION_FAILED');
                setServerProblems(serverResponse)
            }

            SetLoading(false)

        } else {
            setServerProblems(inputCheck);
        }
    };

    /****************************************************** Forgotton Password Handling ***************************************************/

    const handleRecovery = async () => {

        let inputCheck = noProblems(false, false, true);

        if (inputCheck && inputCheck[['isValid']]) {

            SetLoading(true);

            const serverResponse = {
                isValid: true,
                problems: []
            };

            try {
                const response = await fetch(baseURL_recovereacc, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ username: email}),
                });

                const data = await response.json();

                if (data) {
                    if (data['success']) {
                        if (data['recoveryResponse']) {
                            serverResponse.isValid = false;
                            serverResponse.problems.push('Please Check your email for steps to reset your account!');
                            setServerProblems(serverResponse)
                        } else {
                            serverResponse.isValid = false;
                            serverResponse.problems.push('An error occured, code: EMPTY_RECOVERY_RESPONSE');
                            setServerProblems(serverResponse)
                        }

                    } else if (data['error']) {
                        serverResponse.isValid = false;
                        serverResponse.problems.push(data['error']);
                        setServerProblems(serverResponse)

                    } else {
                        serverResponse.isValid = false;
                        serverResponse.problems.push('An error occured, code: EMPTY_RESPONSE');
                        setServerProblems(serverResponse)
                    }
                }

                // Handle the response data
            } catch (error) {
                serverResponse.isValid = false;
                serverResponse.problems.push('An error occured, code: SERVER_CONNECTION_FAILED');
                setServerProblems(serverResponse)
            }

            SetLoading(false)

        } else {
            setServerProblems(inputCheck);
        }
    };

    /****************************************************** Form Handling ***************************************************/

    useEffect(() => {
        setServerProblems(null);
    }, [formIndex]);

    const handleFormChange = (val) => {
        setFormIndex(val);
    };

    const handleRecoveryFormChange = () => {
        setRecoveryPage(!recoveryPage);
    };

    useEffect(() => {
        setServerProblems(null);
    },[recoveryPage])

    /****************************************************** Input Problem Handling ***************************************************/
    function noProblems(includeServer = true, includeNull = true, ignorePassword = false) { //Have to add when a user adds 

        //Used when determining if the error box should be shown
        if (includeServer) {

            if ((serverProblems != null && serverProblems[['isValid']]) || (serverProblems == null && includeNull)) {

                if ((usernameProblems != null && usernameProblems[['isValid']]) || (usernameProblems == null && includeNull) || formIndex == LOGIN_FORM) {

                    if ((passwordProblems != null && passwordProblems[['isValid']]) || (passwordProblems == null && includeNull || formIndex == LOGIN_FORM)) {

                        //Everything passed
                        return true;

                    } else {
                        //Password has problems
                        return false;
                    }

                } else {
                    //Username has problems
                    return false;

                }
            } else {
                //Server has problems
                return false;
            }


            //Used before attempting to contact server
        } else {

            const result = {
                isValid: true,
                problems: []
            };

            if ((usernameProblems != null && usernameProblems[['isValid']]) || (usernameProblems == null && includeNull)) {

                if ((passwordProblems != null && passwordProblems[['isValid']]) || (passwordProblems == null && includeNull) || ignorePassword) {

                    //Everything passed
                    return result;

                } else {
                    //Password has problems
                    if (passwordProblems == null) {
                        result.isValid = false;
                        result.problems.push("Password cannot be empty!");
                    } else {
                        result.isValid = false;
                        result.problems.push("Please correct all password problems!");
                    }
                    return result;
                }

            } else {

                //Username has problems
                if (usernameProblems == null) {
                    result.isValid = false;
                    result.problems.push("Username cannot be empty!");
                } else {
                    result.isValid = false;
                    result.problems.push("Please correct all username problems!");
                }

                return result;
            }
        }
    }

    /****************************************************** Email Input Handling ***************************************************/
    function isValidEmail(email_) {

        setServerProblems(null);

        const result = {
            isValid: true,
            problems: []
        };

        // Regular expression for a simple email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(email_)) {
            result.isValid = false;
            result.problems.push("Username needs to be an email!");
        }

        if ((formIndex == CREATEACC_FORM) && !(email === confirmEmail)) {
            result.isValid = false;
            result.problems.push("Usernames do not match!");
        }

        return result;
    };

    const handleEmailChange = (event) => {
        let email_input = event.target.value//DOMPurify.sanitize(event.target.value)
        setEmail(email_input);
    };

    useEffect(() => {
        let response = isValidEmail(email);
        if (email.length > 0 && response) {
            setUsernameProblems(response);
        } else {
            setUsernameProblems(null);
        }

    }, [email]);

    const handleConfirmEmailChange = (event) => {
        let email_input = event.target.value//DOMPurify.sanitize(event.target.value)
        setConfirmEmail(email_input);
    };

    useEffect(() => {
        let response = isValidEmail(confirmEmail);
        if (confirmEmail.length > 0 && response) {
            setUsernameProblems(response);
        } else {
            setUsernameProblems(null);
        }
    }, [confirmEmail]);

    useEffect(() => {
        if (usernameProblems !== null && usernameProblems[['isValid']]) {
            setRedEmailInput(false);
        } else if (usernameProblems === null || formIndex == LOGIN_FORM) {
            setRedEmailInput(false);
        } else {
            setRedEmailInput(true);
        }
    }, [usernameProblems]);

    /****************************************************** Password Input Handling ***************************************************/

    function isValidPassword(password_) {

        setServerProblems(null);

        const result = {
            isValid: true,
            problems: []
        };

        // Minimum length check
        if (password.length < 8) {
            result.isValid = false;
            result.problems.push("Password should be at least 8 characters long.");
        }

        // Regular expressions to check for presence of uppercase, lowercase, number, and special character
        if (!/[A-Z]/.test(password_)) {
            result.isValid = false;
            result.problems.push("Password should contain at least one uppercase letter.");
        }

        if (!/[a-z]/.test(password_)) {
            result.isValid = false;
            result.problems.push("Password should contain at least one lowercase letter.");
        }

        if (!/[0-9]/.test(password_)) {
            result.isValid = false;
            result.problems.push("Password should contain at least one number.");
        }

        if (!/[$&+,:;=?@#|'<>.^*()%!-]/.test(password_)) {
            result.isValid = false;
            result.problems.push("Password should contain at least one special character.");
        }

        if ((formIndex == CREATEACC_FORM) && !(password === confirmPassword)) {
            result.isValid = false;
            result.problems.push("Passwords do not match!");
        }

        return result;
    }

    const handlePasswordChange = (event) => {
        let input = event.target.value;
        setPassword(input);
    };

    useEffect(() => {
        let response = isValidPassword(password);
        if (password.length > 0 && response) {
            setPasswordProblems(response);
        } else {
            setPasswordProblems(null);
        }
    }, [password]);

    const handleConfirmPasswordChange = (event) => {
        let input = event.target.value;
        setConfirmPassword(input);
    };

    useEffect(() => {
        let response = isValidPassword(confirmPassword);
        if (confirmPassword.length > 0 && response) {
            setPasswordProblems(response);
        } else {
            setPasswordProblems(null);
        }
    }, [confirmPassword]);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    /****************************************************** Content ***************************************************/
    return (
        <LoginForm>
            <TitleBox>
                <Title>{main_title}</Title>
            </TitleBox>

            {recoveryPage === false ? (
                <ControlBox key={"LoginFormControlShow"}>
                    <DynamicSwitch
                        list={['Login', 'Create Account']}
                        index={formIndex}
                        updateRating={handleFormChange}
                    />
                </ControlBox>
            ) : (
                <ControlBox key={"LoginFormControlHide"} />
            )}

            {recoveryPage == true ? (
                //Account Recovery Form
                <InputContainer key={'Recovery Form'} >

                    <Input
                        type='email'
                        placeholder="Email"
                        name="email"
                        value={email}
                        onChange={handleEmailChange}
                        style={{
                            color: redEmailInput ? 'red' : '#53565A',
                            border: redEmailInput ? '2px solid  #8C1515' : '2px solid #2E2D29'
                        }}
                    />

                    <ForgotPassButton
                        onClick={handleRecoveryFormChange}>
                        Log In?
                    </ForgotPassButton>
                    
                </InputContainer>

            ) : formIndex == LOGIN_FORM ? (

                //Manual Login form
                <InputContainer key={'Login Form'} >
                    <Input
                        type='email'
                        placeholder="Email"
                        name="email"
                        value={email}
                        onChange={handleEmailChange}
                        style={{
                            color: redEmailInput ? 'red' : '#53565A',
                            border: redEmailInput ? '2px solid  #8C1515' : '2px solid #2E2D29'
                        }}
                    />
                    <PassBox>
                        <PassInput
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Password"
                            name="password"
                            value={password}
                            onChange={handlePasswordChange}
                            style={{
                                color: redEmailInput ? '#53565A' : '#53565A',
                                border: redEmailInput ? '2px solid  #2E2D29' : '2px solid #2E2D29'
                            }}
                        />
                        <PassShowButton onClick={toggleShowPassword}>
                            {!showPassword ? (
                                <ButtonImage src={hidePassIcon} />
                            ) : (
                                <ButtonImage src={showPassIcon} />
                            )}
                        </PassShowButton>
                    </PassBox>
                    <ForgotPassButton
                        onClick={handleRecoveryFormChange}>
                        Forgot Password?
                    </ForgotPassButton>
                </InputContainer>

            ) : (
                //Create Account Form
                <InputContainer key={'CreateAcc Form'}>
                    <Input
                        type='email'
                        placeholder="Email"
                        name="email"
                        value={email}
                        onChange={handleEmailChange}
                        style={{
                            color: redEmailInput ? 'red' : '#53565A',
                            border: redEmailInput ? '2px solid  #8C1515' : '2px solid #2E2D29'
                        }}
                    />
                    <Input
                        type='email'
                        placeholder="Confirm Email"
                        name="email"
                        value={confirmEmail}
                        onChange={handleConfirmEmailChange}
                        style={{
                            color: redEmailInput ? 'red' : '#53565A',
                            border: redEmailInput ? '2px solid  #8C1515' : '2px solid #2E2D29'
                        }}
                    />

                    <PassBox>
                        <PassInput
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Password"
                            name="password"
                            value={password}
                            onChange={handlePasswordChange}
                            style={{
                                color: redEmailInput ? '#53565A' : '#53565A',
                                border: redEmailInput ? '2px solid  #2E2D29' : '2px solid #2E2D29'
                            }}
                        />
                        <PassShowButton onClick={toggleShowPassword}>
                            {!showPassword ? (
                                <ButtonImage src={hidePassIcon} />
                            ) : (
                                <ButtonImage src={showPassIcon} />
                            )}
                        </PassShowButton>
                    </PassBox>

                    <PassBox>
                        <PassInput
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Confirm Password"
                            name="password"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            style={{
                                color: redEmailInput ? '#53565A' : '#53565A',
                                border: redEmailInput ? '2px solid  #2E2D29' : '2px solid #2E2D29'
                            }}
                        />
                        <PassShowButton onClick={toggleShowPassword}>
                            {!showPassword ? (
                                <ButtonImage src={hidePassIcon} />
                            ) : (
                                <ButtonImage src={showPassIcon} />
                            )}
                        </PassShowButton>
                    </PassBox>
                </InputContainer>
            )}

            <InputContainer>
                {
                    loading ? (
                        <LoadingComponent />
                    ) : noProblems() ? (
                        // Render nothing if there are no problems
                        null
                    ) : formIndex !== CREATEACC_FORM ? (
                        // Render ScaledComponent with null usernameProblems and passwordProblems
                        <ScaledComponent id={id} serverProblems={serverProblems} usernameProblems={null} passwordProblems={null} />
                    ) : (
                        // Render ScaledComponent with usernameProblems and passwordProblems
                        <ScaledComponent id={id} serverProblems={serverProblems} usernameProblems={usernameProblems} passwordProblems={passwordProblems} />
                    )
                }

            </InputContainer>

            <SubmissionBox>
                {recoveryPage == true ? (
                    <Button
                        key={"Recovery Button"}
                        whileTap={{ scale: 0.95 }}
                        whileHover={{ backgroundColor: '#FFF', color: '#820000' }}
                        onClick={handleRecovery}
                    >
                        Reset Password
                    </Button>

                ) : formIndex == 0 ? (
                    <Button
                        key={"Login Button"}
                        whileTap={{ scale: 0.95 }}
                        whileHover={{ backgroundColor: '#FFF', color: '#820000' }}
                        onClick={handleLogin}
                    >
                        Login
                    </Button>
                ) : (
                    <Button
                        key={"Create Account Button"}
                        whileTap={{ scale: 0.95 }}
                        whileHover={{ backgroundColor: '#FFF', color: '#820000' }}
                        onClick={handleCreateAccount}
                    >
                        Create Account
                    </Button>
                )}
            </SubmissionBox>
        </LoginForm>
    );
};

/****************************************************** Styling ***************************************************/

const fadeInOut = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const LoginForm = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  min-width: 32vw;
  width: auto;
  max-width: 90vw;
  min-height: 10vh;
  height: auto;
  max-height: 100vh;
  background: RGB(46, 45, 41, 0.3);
  
  padding: 1vw;
  border: 1px solid black;
  border-radius: 8px;
  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.1);
  margin: auto;
`;

const ControlBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 95%;
  height: auto;

  animation: ${fadeInOut} 1s ease; 
  margin-bottom: 10px;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 95%;
  height: auto;
  padding-bottom: 2vh;
  padding-left: 4vh;
  padding-right: 4vh;
  margin-bottom: 10px;
`;

const SubmissionBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 95%;
  height: 10vh;
  padding-bottom: 2vh;
  padding-left: 4vh;
  padding-right: 4vh;
  margin-bottom: 10px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  width: 90%;
  min-height: 1vh;
  padding: 10px;
  margin-bottom: 10px;

  animation: ${fadeInOut} 1s ease; 
`;

const Input = styled.input`
  width: 100%;
  height: auto;
  
  padding-left: 2px !important;
  padding-right: 0px !important;;
  padding-top: 10px;
  padding-bottom: 10px;
  
  margin-bottom: 10px;
  font-size: 1.5rem;
  border-radius: 5px !important;
`;

const PassBox = styled.div`

  display: flex;
  flex-direction: row;

  width: 100%;
  height: auto;
  font-size: 1.5rem;
  border-radius: 5px !important;

  color: rgb(83, 86, 90);
  border: 2px solid rgb(46, 45, 41);
  background-color: field;
  
  margin-bottom: 10px;
`;

//border: none !important;
const PassInput = styled.input`
  width: 100%;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 2px !important;
  padding-right: 0px;

  font-size: 1.5rem;
  border: none !important;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  background-color: #820000;
  padding: 20px;
  width: 100%;
  color: white;
  text-align: center;
  border-radius: 20px;
  font-size: 2rem;
`;

const Button = styled(motion.button)`
  min-width: 50%;
  padding: 1vh;
  min-height: 1.5rem;
  margin: 10px;
  background-color: #820000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.5rem;

  animation: ${fadeInOut} 1s ease; 
`;

const PassShowButton = styled(motion.button)`
  
  height: auto;
  width: auto;
  border: none !important;
  background: transparent;
  margin: none;
  padding: none;
  cursor: pointer;
`;

const ForgotPassButton = styled(motion.button)`
  height: auto;
  width: auto;
  border: none !important;
  background: transparent;
  margin: none;
  
  padding: none;

  color: blue;
  text-decoration: underline;
  cursor: pointer;

`;


const ButtonImage = styled.img`
    z-index: 2;
    width: auto;
    height: auto;

    background: color: 
    margin: none;
    padding:none;

    &:hover{
        scale: 1.1;
    }

    &:active{
        scale: 0.9;
    }
`;

export default Login;