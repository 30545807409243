import React, { useState, useEffect } from 'react';
//import PAGE_DATA from './Data/PAGE_DATA';
//import SCALE_DATA from './Data/SCALE_DATA';

import MainContainer from "./website_packages/containers/main_container";
import LoginContainer from './website_packages/containers/LoginContainer';
import ScrollContainer from './website_packages/containers/ScrollContainer';

import Login from './website_packages/controls/Login/Login';
import IntroPage from './Pages/IntroPage';
import Questionnaire from './website_packages/questionnaire/Questionnaire';
import PageNavTop from './website_packages/controls/PageNav/PageNavTop';
import PageNav from './website_packages/controls/PageNav/PageNav';

import './App.css';

const LOGIN_PAGE = -1;
const INTRO_PAGE = 0;
const HOME_PAGE = 1;

function App() {

  const [deviceType, setDeviceType] = useState('');
  const [currentPage, setCurrentPage] = useState(LOGIN_PAGE)//LOGIN_PAGE); // -2 is home

  const [userCredentials, setUserCredentials] = useState(null);

  const FIRST_PAGE = 0;
  //const LAST_PAGE = Object.keys(PAGE_DATA).length - 1;

  /****************************************************** Data Loading ***************************************************/

  const [PAGE_DATA, setPageData] = useState(null);
  const [SCALE_DATA, setScaleData] = useState(null);
  const [LAST_PAGE, setLastPage] = useState(0);


  useEffect(() => {
    // Fetch page data
    fetch('/Data/PAGE_DATA.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        //console.log(data);
        setPageData(data)

      })
      .catch(error => {
        console.error('Error fetching page data:', error);
      });

      fetch('/Data/SCALE_DATA.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        //console.log(data);
        setScaleData(data)

      })
      .catch(error => {
        console.error('Error fetching page data:', error);
      });
  }, []);

  useEffect(() => {
    //console.log(PAGE_DATA)
    if (PAGE_DATA) {
      setLastPage(Object.keys(PAGE_DATA).length - 1)
    }
  }, [PAGE_DATA]);


  /****************************************************** DB Access ***************************************************/
  const build = false; //process.env.NODE_ENV  === 'development'
  const baseURL = build === true ? '' : 'https://mecfs.website/';
  const baseURL_auth = build === true ? 'https://me-cfstrackerapp.su.domains/auth.php' : `https://mecfs.website/PHP/ManualAuth.php`;
  const baseURL_authcookie = build === true ? 'https://me-cfstrackerapp.su.domains/auth.php' : `https://mecfs.website/PHP/CookieAuth.php`;
  const baseURL_createacc = build === true ? 'createacc.php' : `https://mecfs.website/PHP/CreateAccount.php`;
  const baseURL_recovereacc = build === true ? 'createacc.php' : `https://mecfs.website/PHP/RecoverAcc.php`;
  const baseURL_responses = build === true ? 'addresponse.php' : `https://mecfs.website/AddResponse.php`;

  /****************************************************** Page Index ***************************************************/
  function setPage(val) {
    window.scrollTo({
      top: 0,
      //behavior: 'smooth' // Optional: for smooth scrolling behavior
    });
    setCurrentPage(val);
  }

  /*
  useEffect(() => {
    console.log('Current Page App', currentPage)
  }, [currentPage]);
  */

  /****************************************************** Set Banner ***************************************************/
  useEffect(() => {
    document.title = 'ME/CFS Tracker App';
    document.querySelector('meta[name="theme-color"]').setAttribute('content', '#820000');
  }, []);

  /****************************************************** Set Device Type ***************************************************/
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    const updateDeviceType = () => {
      if (mediaQuery.matches) {
        setDeviceType('mobile');
      } else {
        setDeviceType('desktop');
      }
    };
    updateDeviceType();
    const resizeListener = () => {
      updateDeviceType();
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  /****************************************************** Login Handling ***************************************************/

  function on_login(info) {
    //Should set username/password here
    //console.log('Logged In')
    //console.log(info)
    if (info) {
      setUserCredentials(info)
    }
  }

  useEffect(() => {
    if (userCredentials) {
      setPage(FIRST_PAGE)
    }
  }, [userCredentials]);

  /****************************************************** Response Handling ***************************************************/

  const sendResponses = () => {

  }

  /****************************************************** App Content ***************************************************/
  return (
    <MainContainer>
      {currentPage != LOGIN_PAGE && (
        <PageNavTop
          FIRST_PAGE={FIRST_PAGE}
          LAST_PAGE={LAST_PAGE}
          sp={setPage}
          currentPage={currentPage}
          username={null}//userCredentials[['user']]}
        />
      )}
      {currentPage == LOGIN_PAGE ? (
        <LoginContainer>
          <Login
            main_title={"ME/CFS Tracker App"}
            onLogin={on_login}
            baseURL_auth={baseURL_auth}
            baseURL_authcookie={baseURL_authcookie}
            baseURL_createacc={baseURL_createacc}
            baseURL_recovereacc={baseURL_recovereacc}
          />
        </LoginContainer>
      ) : (currentPage == INTRO_PAGE ? (
        <IntroPage
          currentPage={INTRO_PAGE}
        />
      ) : (
        <Questionnaire
          pd={PAGE_DATA}
          sd={SCALE_DATA}
          sp={setPage}
          dt={deviceType}
          sr={sendResponses}
          ur={sendResponses}
          currentPage={currentPage}
          baseURL_responses={baseURL_responses} />
      ))}
      {currentPage != LOGIN_PAGE && (
        <PageNav
          FIRST_PAGE={FIRST_PAGE}
          LAST_PAGE={LAST_PAGE}
          sp={setPage}
          currentPage={currentPage}
        />
      )}
    </MainContainer>

  );
}

export default App;
