import React from 'react';
import styled from 'styled-components';

const QuestionnaireContainer = ({ children }) => {
    return (
      <Container>
            {children}
      </Container>
    );
  };
  
  export default QuestionnaireContainer;
  
  const Container = styled.div`
      display: flex;
      width: 100%;
      height: 100%;
      background-color: transparent;
      overflow-y: scroll;
  `;
  