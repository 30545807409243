import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import LeftArrow from './../../images/icons/chevron_left_FILL0_wght400_GRAD0_opsz24.svg'
import RightArrow from './../../images/icons/chevron_right_FILL0_wght400_GRAD0_opsz24.svg'

const PageNavTop = ({ FIRST_PAGE, LAST_PAGE, username = null, sp, currentPage }) => {

    const [page, setPage] = useState(currentPage);

    /****************************************************** Prop Handling ***************************************************/
    useEffect(() => {
        setPage(currentPage);
    }, [currentPage]);

    function next_page() {
        if (page != LAST_PAGE) {
            sp(page + 1)
        }
    }
    function past_page() {
        if (page != FIRST_PAGE) {
            sp(page - 1)
        }
    }

    function set_click() {

    }

    function set_submit() {

    }

    /****************************************************** Component Contents ***************************************************/
    return (
        <Row>
            {page != FIRST_PAGE ?
                (
                    <PageButton onClick={past_page}>
                        <ButtonImage src={LeftArrow} />
                    </PageButton>
                ) : (
                    <PageButtonPlaceHolder>
                        <ButtonImage src={LeftArrow} />
                    </PageButtonPlaceHolder>
                )
            }

            {username && (
                <UserName>
                    <Text>
                        User:
                    </Text>
                    <Text>
                        {username}
                    </Text>
                </UserName>
            )}

            {page != LAST_PAGE ?
                (
                    <PageButton onClick={next_page}>
                        <ButtonImage src={RightArrow} />
                    </PageButton>
                ) : (
                    <PageButtonPlaceHolder >
                        <ButtonImage src={LeftArrow} />
                    </PageButtonPlaceHolder>
                )
            }
        </Row>
    );
};

/****************************************************** Styling ***************************************************/
const Row = styled.div`
    
    top: 0;
    width: 100%;
    height: 8vh;
    max-height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    
    color: #820000;

    @media only screen and (max-device-width: 736px) {
        bottom: 15vh;
        width: 100%;
    }
    overflow:hidden;

    border: 3px solid #820000;

`;

const PageButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    visibility: visible;
    width: 7vh;
    height: 70%;

    border: none;
    
    background-color:   transparent;
    
    overflow:hidden;
    margin: 0;
    padding: 0;
`;

const PageButtonPlaceHolder = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    visibility: visible;
    width: 7vh;
    height: 70%;

    border: none;
    
    background-color:   transparent;
    opacity: 0;
    overflow:hidden;
    margin: 0;
    padding: 0;
`;

const PageButtonPlaceHolder_ = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 10vh;
    min-height: 7vh;
    opacity: 0;
    border: none;
    
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0;
    padding: 0;

    @media only screen and (max-device-width: 736px) {
        bottom: 8vh;
        min-height: 11vh;
    }
`;

const ButtonImage = styled.img`
    z-index: 2;
    width: 80%;
    height: auto;

    background: color: 
    margin: none;
    padding:none;

    
    background: #820000;

    &:hover{
        scale: 1.1;
    }

    &:active{
        scale: 0.9;
    }
`;

const UserName = styled.div`
    font-family: 'Source Sans 3', sans-serif;
    
    width: auto;
    height: auto;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    color: #820000;
    text-align: center;
    padding: 0;
    background: transparent;
`;

const Text = styled.div`
    font-family: 'Source Sans 3', sans-serif;
    
    display: flex;
    width: auto;
    
    height: auto;
    
    margin-left: 10px;
    color: #820000;
    text-align: center;
    padding: 0;
    background: transparent;
`;



export default PageNavTop;
