// components/Home/Home.js
import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import QuestionnairePage from './QuestionnairePage'
import LoadingComponent from '../animations/Loading/LoadingComponent';


/*
pd -> page data 
sd -> scale data
sp -> set current page
dt -> device type
sr -> send responses
ur -> update responses
currentPage -> the page index the user is currently on
baseURL_response -> 
*/

const Questionnaire = ({ pd, sd, sp, dt, sr, ur, currentPage, baseURL_responses }) => {

    const [pageData, setPageData] = useState(pd);
    const [scaleData, setScaleData] = useState(sd);
    const [pageTitles, setPageTitles] = useState(null);

    const [page, setPage] = useState(null)

    const [displayedPage, setDisplayedPage] = useState(null)

    const [click, setClick] = useState(false)
    const [submit, setSubmit] = useState(false)
    const [loading, SetLoading] = useState(false);

    /****************************************************** Prop Handling ***************************************************/
    useEffect(() => {
        setScaleData(sd);
    }, [sd]);

    useEffect(() => {
        setPage(currentPage);
    }, [currentPage]);

    useEffect(() => {
       // console.log('Current Page ques', page)
      }, [page]);
    

    useEffect(() => {
        //console.log('CurrentPage', page)
    }, [page]);

    useEffect(() => {
        setPageData(pd);
    }, [pd]);

    useEffect(() => {
        let temp = [];
        Object.keys(pageData).map((item, index) => (
            temp.push(item)
        ))
        setPageTitles(temp)
    }, [pageData]);

    useEffect(() => {
        if (pageTitles) {
            ////console.log('Titles', pageTitles)
            ////console.log('PageData', pageData)
        }
    }, [pageTitles]);

    /****************************************************** Response Submission Handling ***************************************************/
    /*
    useEffect(() => {
        //console.log('click', click)
        if (submit && click == false) {
            //console.log('IMPORTANT', 1)
            //sendResponses()
            //send json to php here
        }
    }, [click]);

    useEffect(() => {
        //console.log('submit', submit)
        setClick(true)
    }, [submit]);

    useEffect(() => {
        if (!loading) {
            setSubmit(false)
        }
    }, [loading]);
    */
    /****************************************************** Page Data to CSS ***************************************************/
    useEffect(() => {

        if (pageData != null && pageTitles != null && page > 0 && scaleData != null) {
            setDisplayedPage(
                < QuestionnairePage
                    title={pageTitles[page]}
                    alias={pageTitles[page]}
                    paragraphs={pageData[pageTitles[page]]}
                    updateResponses={ur}
                    click={click}
                    list={scaleData["Yes_or_No"]} >
                </QuestionnairePage >
            )
        }

    }, [pageTitles, scaleData, page]);


    /****************************************************** App Content ***************************************************/
    return (
        <Container>
            {displayedPage}
        </Container>
    );
};

/****************************************************** Styling ***************************************************/

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    text-align: center;
    width: 100%;
    min-height: 100vh;
    height: auto;
    
    background-color:  transparent;
    
    overflow-y: hidden;
    overflow-x: hidden;
`;

export default Questionnaire;
