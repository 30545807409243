import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import LeftArrow from './../../images/icons/chevron_left_FILL0_wght400_GRAD0_opsz24.svg'
import RightArrow from './../../images/icons/chevron_right_FILL0_wght400_GRAD0_opsz24.svg'

const PageNav = ({ FIRST_PAGE, LAST_PAGE, sp, currentPage }) => {

    const [page, setPage] = useState(currentPage);

    /****************************************************** Prop Handling ***************************************************/
    useEffect(() => {
        setPage(currentPage);
    }, [currentPage]);

    function next_page() {
        if (page != LAST_PAGE) {
            sp(page + 1)
        }
    }
    function past_page() {
        if (page != FIRST_PAGE) {
            sp(page - 1)
        }
    }

    function set_click() {

    }

    function set_submit() {

    }

    /****************************************************** Component Contents ***************************************************/
    return (
        <Row>
            {page != FIRST_PAGE && (
                <PageButton onClick={past_page}>
                    <ButtonImage src={LeftArrow} />
                </PageButton>
            )}

            {page != LAST_PAGE ? (
                <Button2
                    $whileTap={{ scale: 0.95 }}
                    $whileHover={{ backgroundColor: '#FFF', color: '#820000' }}
                    onClick={set_click}
                >
                    Save Page Responses
                </Button2>
            ) : (
                <Button2
                    $whileTap={{ scale: 0.95 }}
                    $whileHover={{ backgroundColor: '#FFF', color: '#820000' }}
                    onClick={set_submit}
                >
                    Submit Form!
                </Button2>
            )}

            {page != LAST_PAGE && (
                <PageButton onClick={next_page}>
                    <ButtonImage src={RightArrow} />
                </PageButton>
            )}
        </Row>
    );
};

/****************************************************** Styling ***************************************************/
const Row = styled.div`
    display: flex;
    bottom: 2vh;
    width: auto;
    height: 8vh;
    max-height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;
    

    @media only screen and (max-device-width: 736px) {
        bottom: 15vh;
        width: 100%;
    }
    overflow:hidden;

    border: 3px solid #820000;

`;

const Button2 = styled.button`
  min-width: 50%;
  
  
  margin: 10px;
  background-color: #820000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.5rem;


  height: 70%;
  &:hover{
    scale: 1.1;
}

&:active{
    scale: 0.9;
}
`;

const PageButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    visibility: visible;
    width: 7vh;
    height: 70%;

    border: none;
    
    background-color:   transparent;
    
    overflow:hidden;
    margin: 0;
    padding: 0;
`;

const PageButtonPlaceHolder = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 10vh;
    min-height: 7vh;
    opacity: 0;
    border: none;
    
    background-color:   red;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0;
    padding: 0;

    @media only screen and (max-device-width: 736px) {
        bottom: 8vh;
        min-height: 11vh;
    }
`;

const ButtonImage = styled.img`
    z-index: 2;
    width: 80%;
    height: auto;

    background: color: 
    margin: none;
    padding:none;

    
    background: #820000;

    &:hover{
        scale: 1.1;
    }

    &:active{
        scale: 0.9;
    }
`;

export default PageNav;
