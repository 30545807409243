import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import INTRO_DATA from './../Data/INTRO_DATA'
import MECFS_CHART from './../Data/imgs/MECFS_CHART.png'

//import INTRO_DATA from './../../public/Data/INTRO_DATA'
//import MECFS_CHART from './../../public/Data/MECFS_CHART.png'

const IntroPage = ({currentPage}) => {

    const [page, setPage] = useState(currentPage)
    const [displayedPage, setDisplayedPage] = useState(null)

    useEffect(() => {
        setPage(currentPage);
    }, [currentPage]);

    useEffect(() => {
        if (page >= 0 && INTRO_DATA[page]) {
            setDisplayedPage(
                <TextBlock>
                    <Title>{INTRO_DATA[page]['Title']}</Title>
                    <SubTitle>{INTRO_DATA[page]['SubTitle']}</SubTitle>
                    <Body>{INTRO_DATA[page]['Body']}</Body>
                    <SubTitle>{INTRO_DATA[page]['SubTitle1']}</SubTitle>
                    <Body>{INTRO_DATA[page]['Body1']}</Body>
                    <SubTitle>{INTRO_DATA[page]['SubTitle2']}</SubTitle>
                    <Body>{INTRO_DATA[page]['Body2']}</Body>
                    <StyledImage src={MECFS_CHART} />
                </TextBlock>
            );
        }
    }, [page]);

    return (
        <RetContainer>
            {displayedPage}
        </RetContainer>
        
    );
};

const RetContainer = styled.div`
    display: flex;
    min-height: 100%;
    padding-bottom: 1vh;
`;


const TextBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    text-align: left;

    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    height: auto;
    scale: 1;
    border: none;
    
    background-color: transparent;
    overflow-y: scroll;
    overflow-x: hidden;
    
    @media only screen and (max-device-width: 736px) {
        padding-top: 0vh;
    }

`;

const Title = styled.div`
    font-family: 'Source Sans 3', sans-serif;
    font-size: 5vh;
    width: auto;
    
    min-height: 6vh;
    height: auto;
    max-height: 6vh;
    background: transparent;
    color: #820000;
    text-align: center;
    padding: 0;
    margin-bottom: 5vh;
    border-bottom: 3px solid #EAEAEA;
`;

const SubTitle = styled.div`
    font-family: 'Source Sans 3', sans-serif;
    font-size: 2.3rem;
    width: auto;
    min-height: auto;
    background: transparent;
    color: #820000;
    text-align: center;
    padding: 0;
    margin-bottom: 2vh;

`;

const Body = styled.div`
    font-family: 'Source Sans 3', sans-serif;
    font-size: 1.5rem;
    width: 90%;
    min-height: auto;
    
    color: black;
    text-align: center;
    padding: 0;
    margin-bottom: 2vh;

    @media only screen and (max-device-width: 736px) {
        width: 100%;
    }
`;

const StyledImage = styled.img`
    z-index: 2;
    max-width: 100%;
    width: auto;
    height: auto;
    object-fit: contain;

    

    @media only screen and (max-device-width: 736px) {
        padding-bottom: 25vh;
    }
    
`;

export default IntroPage