// LoginContainer.js
import React from 'react';
import styled from 'styled-components';

const LoginContainer = ({ children }) => {
    return (
        <FullPage>
            {children}
        </FullPage>
    );
};

export default LoginContainer;

const FullPage = styled.div`
    height: 100vh;
    width: auto;
    display: flex;

`;


